<template lang="pug">
	article#finalizeCompra
		.wrapper
			h2 Finalize sua compra
			form(@submit.prevent="processPayment")
				.input(:class="{'ativo': form.nome, error: errors.nome}")
					input(id="nome" name="nome" type="text" v-model="form.nome")
					label(for="nome") Nome
					span(v-if="errors.nome") {{errors.nome[0]}}
				.input(:class="{'ativo': form.nome, error: errors.sobrenome}")
					input(id="sobrenome" name="sobrenome" type="text" v-model="form.sobrenome")
					label(for="sobrenome") Sobrenome
					span(v-if="errors.sobrenome") {{errors.sobrenome[0]}}
				.input(:class="{'ativo': form.email, error: errors.email}")
					input(id="email" name="email" type="email" v-model="form.email")
					label(for="email") Email
					span(v-if="errors.email") {{errors.email[0]}}
				.input(:class="{'ativo': formMercadoPago.docNumber, error: errors.documento}", v-mask="'###########'")
					input(id="docNumber" type="tel" data-checkout="docNumber" onselectstart="return false", onpaste="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.docNumber")
					label(for="docNumber") CPF
					span(v-if="errors.documento") {{errors.documento[0]}}
				.input(:class="{'ativo': form.telefone, error: errors.telefone}")._50
					input(id="telefone" name="telefone" type="tel" v-model="form.telefone", v-mask="['(##) #####-####','(##) ####-####']")
					label(for="telefone") Telefone
					span(v-if="errors.telefone") {{errors.telefone[0]}}
				.input(:class="{'ativo': form.cep, error: errors.cep}")._50
					input(id="cep" name="cep" type="tel" v-model="form.cep" v-mask="['##.###-###']")
					label(for="cep") CEP
					span(v-if="errors.cep") {{errors.cep[0]}}

				.formaPagamento
					div
						input(type="radio" id="boleto", v-model="form.payment_type_id", value="ticket", name="formaPagamento")
						label(for="boleto") Boleto
							img(:src="require('@images/icones/icone-boleto.png')")
					div
						input(type="radio" id="cartao", v-model="form.payment_type_id", value="credit_card", name="formaPagamento")
						label(for="cartao") Cartão de Crédito
							img(:src="require('@images/icones/icone-cartoes.png')")

				
				.input(v-if="form.payment_type_id == 'credit_card'" :class="{'ativo': formMercadoPago.cardNumber}")
					input(id="cardNumber" data-checkout="cardNumber" onselectstart="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.cardNumber", @change="guessingPaymentMethod")
					label(for="cardNumber") Número do cartão
				.input(v-if="form.payment_type_id == 'credit_card'" :class="{'ativo': formMercadoPago.securityCode}")
					input(id="securityCode" data-checkout="securityCode" onselectstart="return false", onpaste="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.securityCode")
					label(for="securityCode") Código de segurança
				.input(v-if="form.payment_type_id == 'credit_card'" :class="{'ativo': formMercadoPago.cardExpirationMonth}")._50
					input(id="cardExpirationMonth" v-mask="'##'" data-checkout="cardExpirationMonth" onselectstart="return false", onpaste="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.cardExpirationMonth")
					label(for="cardExpirationMonth") Mês expiração
				.input(v-if="form.payment_type_id == 'credit_card'" :class="{'ativo': formMercadoPago.cardExpirationYear}")._50
					input(id="cardExpirationYear" v-mask="'####'" data-checkout="cardExpirationYear" onselectstart="return false", onpaste="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.cardExpirationYear")
					label(for="cardExpirationYear") Ano expiração
				.input(v-if="form.payment_type_id == 'credit_card'")
					select(v-model="form.parcelas")
						option(:value="null", disabled) Parcelas
						option(v-for="parcela in parcelas" v-bind:value="parcela.value") {{parcela.text}}
				.input(v-if="form.payment_type_id == 'credit_card'" :class="{'ativo': formMercadoPago.cardholderName}")
					input(id="cardholderName" data-checkout="cardholderName" onselectstart="return false", onpaste="return false", oncopy="return false", oncut="return false", ondrag="return false", ondrop="return false" v-model="formMercadoPago.cardholderName")
					label(for="cardholderName") Nome do cartão
				// .remember(v-if="form.payment_type_id == 'credit_card'")
				// 	input(id="remember_card" name="remember_card" type="checkbox" v-model="form.remember_card")
				// 	label(for="remember_card") Lembrar do cartão
				button(:disabled="sending") Efetuar pagamento
			
			div.items(v-if="$carrinho.carrinho")
				h4 Seu pedido
				.cabecalho
					p.produto Produto
					p.total Total
				hr
				.item(v-for="item, index in $carrinho.carrinho.produtos")
					p.itemNome {{ item.quantidade }}x {{ item.produto.produto.nome }}
					p.valor R$ {{ item.produto.preco_total | fixed }}
				hr.last
				.cabecalho
					p.produto Total do pedido
					p.total R$ {{ $carrinho.carrinho.total | fixed }}
				.cabecalho
					p.produto Com desconto boleto
					p.total R$ {{ $carrinho.carrinho.total_boleto | fixed }}

		AsideConfirmado(:ativo.sync="asideConfirmacao", :confirmMethod="doPay", :status="asideStatus", :response="asideResponse")

</template>

<script>
import AsideConfirmado from '@vue/sections/finalizeCompra/asideConfirmado/AsideConfirmado'
import { mask } from 'vue-the-mask'

export default {
	components: {
		AsideConfirmado
	},
	directives: {
		mask
	},
	data(){
		return {
			asideConfirmacao: false,
			asideStatus: 'validacao',
			asideResponse: {},
			sending: false,
			form: {
				email:'',
				token:'',
				payment_type_id: "ticket",
				bandeira:'',
				issuer_id:'',
				quatro_ultimos:'',
				payment_method_id:'bolbradesco',
				remember_card:false,
				parcelas: null
			},
			formMercadoPago: {
				cardNumber:'',
				securityCode:'',
				cardExpirationMonth:'',
				cardExpirationYear:'',
				cardholderName:'',
				docType:'CPF',
				docNumber:'',
			},
			parcelas: [],
			errors: {}
		}
	},
	created(){
		window.Mercadopago.setPublishableKey(process.env.VUE_APP_MP_PUBLIC_KEY);
	},
	beforeRouteLeave (to, from , next) {
		if (!this.asideConfirmacao && (this.asideStatus != 'confirmado' && !this.asideResponse.erro_carrinho)) {
			const answer = window.confirm('Deseja sair? O pagamento não será finalizado!')
			if (answer)
				next()
			else
				next(false)
		} else {
			next()
		}
	},
	watch: {
		asideConfirmacao: function () {
			if (!this.asideConfirmacao)
				this.sending = false;
			if (!this.asideConfirmacao && this.asideStatus == 'confirmado')
				this.$router.replace({ name: 'home' });
			if (!this.asideConfirmacao && this.asideResponse.erro_carrinho)
				this.$router.replace({ name: 'carrinho' });
		}
	},
	filters: {
		fixed(value) {
			let numero = parseFloat(value).toFixed(2);
			numero = new Number(numero).toLocaleString("pt-BR");
			let position = numero.search(',');
			if (position == -1)
				numero = numero + ",00";
			else{
				if(position + 1 == (numero.length -1)){
					numero = numero + "0";
				}
			}
			return numero;
		}
	},
	methods:{
		getBin(){
			const cardNumber = this.formMercadoPago.cardNumber;
			return cardNumber.replace(/\D+/g,'').substring(0,6);
		},
		guessingPaymentMethod(){
			if(this.formMercadoPago.cardNumber.length >= 6){
				window.Mercadopago.getPaymentMethod({
					"bin" : this.getBin()
				}, this.setPaymentMethodInfo);
			}
		},
		setPaymentMethodInfo(status,response){
			if(status === 200){
				this.form.payment_method_id = response[0].id;
				this.form.bandeira = response[0].id;
				// eslint-disable-next-line
				Mercadopago.getInstallments({
					"bin": this.getBin(),
					"amount": parseFloat(this.$carrinho.carrinho.total),
				}, this.setInstallmentInfo);
			}else{
				console.log(`payment method info error: ${response.message ? response.message : '' }`); // eslint-disable-line
			}
		},
		setInstallmentInfo(status, response){
			if(response.length > 0){
				this.form.issuer_id = response[0].issuer.id;
				let payerCosts = response[0].payer_costs;
				this.parcelas = [];
				for (let i = 0; i < payerCosts.length; i++) {
					this.parcelas.push({
						text: payerCosts[i].recommended_message,
						value: payerCosts[i].installments
					});
				}
			}else{
				console.log("Cartão inválido"); // eslint-disable-line
			}
		},
		doPay(){
			this.asideStatus = 'processando';
			if (this.form.payment_type_id == "credit_card")
				window.Mercadopago.createToken(this.formMercadoPago, this.sdkResponseHandler);
			else
				this.sdkResponseHandler(200)
			return false;
		},
		sdkResponseHandler(status, response = null){
			if(status !== 200 && status !== 201){
				this.asideStatus = 'erro';
			} else {
				if (response) {
					this.form.token = response.id;
					this.form.quatro_ultimos = response.last_four_digits;
				}
				this.form.documento = this.formMercadoPago.docNumber;
				this.errors = {}
				this.$axios
					.post('api/pedido/finalizar-compra', this.form)
					.then(response => {
						this.asideStatus = 'confirmado';
						this.asideResponse = response.data;
						this.$carrinho.__removeLocal();
					})
					.catch(error => {
						this.asideStatus = 'erro';
						switch (error.response.status){
							case 400:
								this.asideResponse = error.response.data;
								this.cleanForms();
								break;
							case 422:
								this.asideResponse = error.response.data;
								if (error.response.data.errors)
									this.errors = error.response.data.errors;
								else
									this.cleanForms();
								break;
							default:
								this.cleanForms();
								this.asideResponse = {'message':"Ocorreu um erro ao processar o pagamento. Tente novamente mais tarde"};
						}
					})
			}
		},
		cleanForms() {
			this.form = {
				email:'',
				token:'',
				payment_type_id: "ticket",
				bandeira:'',
				issuer_id:'',
				quatro_ultimos:'',
				payment_method_id:'',
				remember_card:false,
				parcelas: null
			}

			this.formMercadoPago = {
				cardNumber:'',
				securityCode:'',
				cardExpirationMonth:'',
				cardExpirationYear:'',
				cardholderName:'',
				docType:'CPF',
				docNumber:'',
			}
		},
		processPayment() {
			this.asideConfirmacao = true;
			this.sending = true;
			this.asideStatus = 'validacao';
		}
	}
}
</script>

<style lang="stylus" scoped src="./FinalizeCompra.styl"></style>